<template>
  <div>

    <!-- <div class="row">
      <div class="col-md-12">
        <div class="card border-top rounded shadow p-3">
          <div class="row justify-content-between">
            <div class="col-md-6">
              <p class="p-0 m-0"><b>Jumlah Berdasarkan Jenis Prasarana</b></p>
            </div>
            <div class="col-md-6 d-flex justify-content-end">
              <select
                style="max-width: 200px"
                class="form-control form-control-sm mx-2"
                v-model="paramPrasarana.kecamatan"
                @change="getPrasarana()"
              >
                <option value="">Semua Kecamatan</option>
                <option
                  v-for="kecamatan in kecamatans"
                  :value="kecamatan.name"
                  :key="kecamatan.name"
                >
                  {{ kecamatan.name }}
                </option>
              </select>

              <select
                style="max-width: 100px"
                class="form-control form-control-sm mx-2"
                v-model="paramPrasarana.year"
                @change="getPrasarana()"
              >
                <option value="">Semua Kondisi</option>
                <option>2024</option>
                <option>2023</option>
                <option>2022</option>
                <option>2021</option>
                <option>2020</option>
              </select>

              <select
                class="form-control form-control-sm mx-2"
                v-model="paramPrasarana.jenis_pemilihan"
                @change="getPrasarana()"
              >
                <option value="">Semua Kondisi</option>
                <option value="Terbangun">Terbangun</option>
                <option value="Tidak Terbangun">Tidak Terbangun</option>
              </select>

              <select
                class="form-control form-control-sm mx-2"
                v-model="paramPrasarana.jenis_lahan"
                @change="getPrasarana()"
              >
                <option value="">Semua Jenis Lahan</option>
                <option value="LSD">LSD</option>
                <option value="BUKAN LSD">BUKAN LSD</option>
              </select>

              <select
                class="form-control form-control-sm mx-2"
                v-model="paramPrasarana.jenis_pekerjaan"
                @change="getPrasarana()"
              >
                <option value="">Semua Jenis Prasarana</option>
                <option
                  v-for="cate in category"
                  :value="cate.name"
                  :key="cate.name"
                >
                  {{ cate.name }}
                </option>
              </select>
            </div>
          </div>
          <hr />

          Table to display data
          <div class="table-responsive">
            <table class="table table-bordered table-striped">
              <thead>
                <tr>
                  <th>No</th>
                  <th>Jenis Prasarana</th>
                  <th>Jumlah Prasarana</th>
                  <th>Jumlah Volume</th>
                  <th>Satuan</th>
                  <th>Nilai Anggaran</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in groupedData.data" :key="item.jenis_pekerjaan" @click="handleRowClick(item)" style="cursor: pointer;">
                  <td>{{ index + 1 }}</td>
                  <td>{{ item.jenis_pekerjaan }}</td>
                  <td>{{ item.jumlah_prasarana }}</td>
                  <td>{{ item.volume.toLocaleString() }}</td>
                  <td v-if="item.jenis_pekerjaan.includes('Jaringan') || item.jenis_pekerjaan.includes('Jalan')">
                    Meter
                  </td>
                  <td v-else>
                    Unit
                  </td>
                  <td>{{ item.nilai_kontrak.toLocaleString() }}</td>
                </tr>
                <tr v-if="groupedData.data && (groupedData.data.length === 0)">
                  <td colspan="6" class="text-center">No Data Available</td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td colspan="5" class="text-center"><strong>Total Nilai Anggaran</strong></td>
                  <td><strong>{{ groupedData.totalNilaiKontrak.toLocaleString() }}</strong></td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </div> -->

    <!-- <div class="row">
      <div class="col-md-12">
        <div class="card border-top rounded shadow p-3">
          <div class="row justify-content-between">
            <div class="col-md-6">
              <p class="p-0 m-0"><b>Total Anggaran Berdasarkan Jenis Prasarana</b></p>
            </div>
            <div class="col-md-6 d-flex justify-content-end">
              <select
                style="max-width: 200px"
                class="form-control form-control-sm mx-2"
                v-model="paramPrasarana2.kecamatan"
                @change="getPrasaranaAll()"
              >
                <option value="">Semua Kecamatan</option>
                <option
                  v-for="kecamatan in kecamatans"
                  :value="kecamatan.name"
                  :key="kecamatan.name"
                >
                  {{ kecamatan.name }}
                </option>
              </select>

              <select
                class="form-control form-control-sm mx-2"
                v-model="paramPrasarana2.jenis_pemilihan"
                @change="getPrasaranaAll()"
              >
                <option value="">Semua Kondisi</option>
                <option value="Terbangun">Terbangun</option>
                <option value="Tidak Terbangun">Tidak Terbangun</option>
              </select>

              <select
                class="form-control form-control-sm mx-2"
                v-model="paramPrasarana2.jenis_lahan"
                @change="getPrasaranaAll()"
              >
                <option value="">Semua Jenis Lahan</option>
                <option value="LSD">LSD</option>
                <option value="BUKAN LSD">BUKAN LSD</option>
              </select>

              <select
                class="form-control form-control-sm mx-2"
                v-model="paramPrasarana2.jenis_pekerjaan"
                @change="getPrasarana()"
              >
                <option value="">Semua Jenis Prasarana</option>
                <option
                  v-for="cate in category"
                  :value="cate.name"
                  :key="cate.name"
                >
                  {{ cate.name }}
                </option>
              </select>
            </div>
          </div>
          <hr />

          <div class="table-responsive">
            <table class="table table-bordered table-striped">
              <thead>
                <tr>
                  <th>No</th>
                  <th>Jenis Prasarana</th>
                  <th>Jumlah Prasarana</th>
                  <th>Nilai Anggaran</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in groupedData2.data" :key="item.jenis_pekerjaan">
                  <td>{{ index + 1 }}</td>
                  <td>{{ item.jenis_pekerjaan }}</td>
                  <td>{{ item.jumlah_prasarana }}</td>
                  <td>{{ item.nilai_kontrak.toLocaleString() }}</td>
                </tr>
                <tr v-if="groupedData2.data && (groupedData2.data.length === 0)">
                  <td colspan="4" class="text-center">No Data Available</td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td colspan="3" class="text-center"><strong>Total Nilai Anggaran</strong></td>
                  <td><strong>{{ groupedData2.totalNilaiKontrak.toLocaleString() }}</strong></td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </div> -->


    <!-- <div class="row">
      <div class="col-md-12">
      <div class="card border-top rounded shadow p-3">
          <div class="row row justify-content-between">
            <div class="col"><p class="p-0 m-0"><b> Jumlah Berdasarkan Jenis Prasarana Tahunan </b></p></div>
          </div>
          <hr />
          <CChartLineExample />
        </div>
      </div>
    </div> -->

    <div class="row">
      <!-- <div class="col-md-6">
        <div class="card border-top rounded shadow p-3">
          <p class="p-0 m-0"><b> Persentase Paket Pekerjaan (%)</b></p>
          <hr />
          <MainChartExample />
        </div>
      </div> -->
      <div class="col-md-12">
      <div class="card border-top rounded shadow p-3">
          <div class="row row justify-content-between">
            <div class="col"><p class="p-0 m-0"><b> Jumlah Laporan </b></p></div>
            <div class="col-md-2 offset-md-2">
              <select class="form-control" v-model="tahun" @change="onChange($event)" >
                <option value="2025">2025</option>
                <option value="2024">2024</option>
                <option value="2023">2023</option>
                <option value="2022">2022</option>
                <option value="2022">2021</option>
                <option value="2022">2020</option>
              </select>
            </div>
          </div>
          <hr />
          <CChartBarExample v-if="!isChange" :tahun="tahun" />
        </div>
      </div>
    </div>

    <!-- <div class="row"> -->
      <!-- <div class="col-md-6">
        <div class="card border-top rounded shadow p-3">
          <p class="p-0 m-0"><b> Persentase Paket Pekerjaan (%)</b></p>
          <hr />
          <MainChartExample />
        </div>
      </div> -->
      <!-- <div class="col-md-12">
      <div class="card border-top rounded shadow p-3">
          <div class="row row justify-content-between">
            <div class="col"><p class="p-0 m-0"><b> Jumlah Prasarana Berdasarkan BPP</b></p></div>
            <div class="col-md-2 offset-md-2">
              <select class="form-control" v-model="tahun" @change="onChange($event)" >
                <option value="2025">2025</option>
                <option value="2024">2024</option>
                <option value="2023">2023</option>
                <option value="2022">2022</option>
                <option value="2022">2021</option>
                <option value="2022">2020</option>
              </select>
            </div>
          </div>
          <hr />
          <ChartOpd v-if="!isChange" :tahun="tahun" />
        </div>
      </div>
    </div> -->

    <!-- <div class="row">
      <div class="col-md-6">
        <div class="card border-top rounded shadow p-3">
          <p class="p-0 m-0"><b> Persentase Paket Pekerjaan (%)</b></p>
          <hr />
          <MainChartExample />
        </div>
      </div>
      <div class="col-md-12">
      <div class="card border-top rounded shadow p-3">
          <div class="row row justify-content-between">
            <div class="col"><p class="p-0 m-0"><b> Jumlah Prasarana Berdasarkan Kecamatan</b></p></div>
            <div class="col-md-2 offset-md-2">
              <select class="form-control" v-model="tahun" @change="onChange($event)" >
                <option value="2025">2025</option>
                <option value="2024">2024</option>
                <option value="2023">2023</option>
                <option value="2022">2022</option>
                <option value="2022">2021</option>
                <option value="2022">2020</option>
              </select>
            </div>
          </div>
          <hr />
          <ChartKecamatan v-if="!isChange" :tahun="tahun" />
        </div>
      </div>
    </div> -->

    <!-- <div class="row">
      <div class="col-md-6">
        <div class="card border-top rounded shadow p-3">
          <div class="row row justify-content-between">
            <div class="col"><p class="p-0 m-0"><b> Persentase Sumber Dana (%)</b></p></div>
            <div class="col-md-3 offset-md-3">
              <select class="form-control" v-model="tahun_sumber_dana" @change="onChangeSumberDana($event)" >
                <option value="2025">2025</option>
                <option value="2024">2024</option>
                <option value="2023">2023</option>
                <option value="2022">2022</option>
                <option value="2022">2021</option>
                <option value="2022">2020</option>
              </select>
            </div>
          </div>
          <hr />
          <SumberDanaChart v-if="!isChangeSumberDana" :tahun="tahun_sumber_dana"/>
        </div>
      </div>
      <div class="col-md-6">
        <div class="card border-top rounded shadow p-3">
          <div class="row row justify-content-between">
            <div class="col"><p class="p-0 m-0"><b> Persentase Sumber Dana (APBN)</b></p></div>
            <div class="col-md-3 offset-md-3">
              <select class="form-control" v-model="tahun_sumber_dana" @change="onChangeSumberDana($event)" >
                <option value="2025">2025</option>
                <option value="2024">2024</option>
                <option value="2023">2023</option>
                <option value="2022">2022</option>
                <option value="2022">2021</option>
                <option value="2022">2020</option>
              </select>
            </div>
          </div>
          <hr />
          <SumberDanaAPBNChart v-if="!isChangeSumberDana" :tahun="tahun_sumber_dana"/>
        </div>
      </div>
    </div> -->

    <div class="row">
      <div class="col">
        <div class="card border-top rounded shadow p-3">
          <p class="p-0 m-0"><b> Profile </b></p>

          <hr />
          <div class="row">
            <div class="col-md-4">
              <img width="150px" src="@/assets/logo/pagaralam.png" alt="" srcset="" />
            </div>
            <div class="col">
              <table class="table table-sm table-striped table-responsive">
                <tbody>
                  <tr>
                    <td>Nama</td>
                    <td>{{ user.full_name }}</td>
                  </tr>
                  <tr>
                    <td>NIP</td>
                    <td>{{ user.nip }}</td>
                  </tr>
                  <tr>
                    <td>Role</td>
                    <td>{{ user.role.name }}</td>
                  </tr>
                  <tr>
                    <td>Email</td>
                    <td>{{ user.email }}</td>
                  </tr>
                  <tr>
                    <td>Jabatan</td>
                    <td>{{ user.position }}</td>
                  </tr>

                  <tr>
                    <td>Pangkat / Golongan</td>
                    <td>{{ user.group }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <button
            class="btn btn-sm btn-primary"
            @click="editUser"
            style="width: 100px"
          >
            Edit Profile
          </button>
        </div>
      </div>
    </div>

    <!-- <div class="row">
      <div class="col-md-6">
        <div class="card border-top rounded shadow p-3" style="height: 250px">
          <p class="p-0 m-0"><b> Permintaan Layanan Online Chat </b></p>
          <hr />
          <div class="card-body p-0">
            <center v-if="rooms.length == 0">
              <p>Tidak ada permintaan</p>
            </center>
            <table
              v-if="rooms.length != 0"
              class="table table-sm table-striped dytable"
            >
              <tbody>
                <tr v-for="item in rooms.slice(0, 3)" :key="item.id">
                  <td width="20px">
                    <CIcon
                      name="cil-comment-square"
                      size="custom-size"
                      class="mr-3"
                      :height="25"
                    />
                  </td>
                  <td class="font-weight-bold">
                    {{ item.room_name }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="card-footer py-1 px-0">
            <router-link
              to="/komunikasi/online-chat"
              style="width: 30%"
              class="btn btn-sm btn-primary mt-2 mb-0"
            >
              Selengkapnya
            </router-link>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="card border-top rounded shadow p-3" style="height: 250px">
          <p class="p-0 m-0"><b> Pesan </b></p>
          <hr />
          <div class="card-body p-0">
            <center v-if="inbox.length == 0"><p>Tidak ada pesan</p></center>
            <div class="table-responsive">
              <table
                v-if="inbox.length != 0"
                class="table table-sm table-striped dytable"
              >
                <tbody>
                  <tr v-for="item in inbox" :key="item.id">
                    <td style="vertical-align: center" width="20px">
                      <CIcon
                        name="cil-envelope-closed"
                        size="custom-size"
                        class="mr-3 pb-1"
                        :height="25"
                      />
                    </td>
                    <td class="font-weight-bold">
                      {{ item.message.title }}
                      <small> - dari : {{ item.message.sender.name }}</small>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="card-footer py-1 px-0">
            <router-link
              to="/komunikasi/pesan"
              style="width: 30%"
              class="btn btn-sm btn-primary mt-2 mb-0"
            >
              Selengkapnya
            </router-link>
          </div>
        </div>
      </div>
    </div> -->
    <!-- <div class="row">
      <div class="col-md">
        <schedule-table />
      </div>
    </div> -->
    <CModal
      size="lg"
      title="Edit User"
      centered
      color="primary"
      :show.sync="updateModal"
    >
      <div class="row">
        <div class="col">
          <CInput
            v-model="form.full_name"
            label="Nama Lengkap"
            placeholder="ketik disini"
          />
          <CInput
            v-model="form.nip"
            :readonly="true"
            label="NIP"
            type="number"
            placeholder="12345678"
          />
          <CInput
            v-model="form.email"
            label="Email"
            type="email"
            placeholder="test@email.com"
          />
          <CInput
            v-model="form.password"
            label="Password"
            type="text"
            placeholder="*******"
          />
          <small class="text-danger"
            >Kosongkan password jika tidak ingin mengubah</small
          >
        </div>
        <div class="col">
          <CInput
            v-model="form.position"
            label="Jabatan"
            placeholder="ketik disini"
          />
          <CInput
            v-model="form.group"
            label="Golongan"
            placeholder="ketik disini"
          />
          <CSelect
            :value.sync="form.role_id"
            label="Role"
            disabled
            placeholder="Pilih Role"
            :options="computedRole"
          />
        </div>
      </div>
      <template slot="footer">
        <div>
          <button @click="updateModal = false" class="btn btn-secondary mr-3">
            Batal
          </button>

          <button @click="update" class="btn btn-primary">Update User</button>
        </div>
      </template>
    </CModal>
  </div>
</template>



<script>
import axios from "axios";
import ScheduleTable from "./components/schedule-table.vue";
import MainChartExample from "../charts/CChartDoughnutExample.vue";
import CChartBarExample from "../charts/CChartBarExample.vue";
import CChartLineExample from "../charts/CChartLineExample.vue";
import ChartOpd from "../charts/ChartOpd.vue";
import ChartKecamatan from "../charts/ChartKecamatan.vue";
import SumberDanaChart from "../charts/SumberDanaChart.vue";
import SumberDanaAPBNChart from "../charts/SumberDanaChartAPBN.vue";
import OtherChart from "../charts/OtherChart.vue";
export default {
  components: {
    ScheduleTable,
    MainChartExample,
    ChartOpd,
    ChartKecamatan,
    CChartBarExample,
    CChartLineExample,
    SumberDanaChart,
    OtherChart,
    SumberDanaAPBNChart
  },
  data() {
    return {
      user: {},
      updateModal: false,
      form: {},
      agenda: [],
      roles: [],
      rooms: [],
      inbox: [],
      tahun: 2024,
      tahun_sumber_dana: 2024,
      tahun_quarry: 2024,
      params: {
        sorttype: "desc",
        sortby: "id",
        row: 3,
      },
      isChange: false,
      isChangeSumberDana: false,
      isChangeQuarry: false,
      prasaranaData: null,
      kecamatans: [],
      paramPrasarana: {
        sorttype: "desc",
        sortby: "id",
        row: 1000,
        page: 1,
        type: [0, 1],
        keyword: "",
        year: "",
        kecamatan: "",
        jenis_pemilihan: "",
        jenis_lahan: "",
        jenis_pekerjaan: ""
      },
      paramPrasarana2: {
        sorttype: "desc",
        sortby: "id",
        row: 1000,
        page: 1,
        type: [0, 1],
        keyword: "",
        kecamatan: "",
        jenis_pemilihan: "",
        jenis_lahan: "",
        jenis_pekerjaan: ""
      },
      category: [],
      items: [],
      items2: []
    };
  },
  methods: {
    onChange(event) {
      this.isChange = true;
      var loading = this.$loading.show();
      setTimeout(() => {
        let tahun = event.target.value;
        this.tahun = tahun;
        this.isChange = false;
        loading.hide();
      }, 100);
    },
    onChangeSumberDana(event) {
      this.isChangeSumberDana = true;
      var loading = this.$loading.show();
      setTimeout(() => {
        let tahun_sumber_dana = event.target.value;
        this.tahun_sumber_dana = tahun_sumber_dana;
        this.isChangeSumberDana = false;
        loading.hide();
      }, 100);
    },
    onChangeQuarry(event) {
      this.isChangeQuarry = true;
      var loading = this.$loading.show();
      setTimeout(() => {
        let tahun_quarry = event.target.value;
        this.tahun_quarry = tahun_quarry;
        this.isChangeQuarry = false;
        loading.hide();
      }, 100);
    },
    getData() {
      var loading = this.$loading.show();
      axios
        .get("/agenda", { params: this.params })
        .then((resp) => {
          this.agenda = resp.data.data.data;

          loading.hide();
        })
        .catch((e) => {
          this.$toast.error(e);
          loading.hide();
        });
    },
    editUser() {
      this.form = this.user;
      this.updateModal = true;
    },
    update() {
      var loading = this.$loading.show();
      if (this.form.password == null) {
        this.$store
          .dispatch("user/updateUser", { id: this.form.id, data: this.form })
          .then(() => {
            this.$toast.success("Berhasil merubah data user");
            loading.hide();
            this.updateModal = false;
            this.form = {};
            this.getMe();
          })
          .catch((e) => {
            this.$toast.error(e);
            this.updateModal = false;
            loading.hide();
          });
      } else {
        this.$store
          .dispatch("user/changePassword", {
            id: this.form.id,
            password: this.form.password,
          })
          .then(() => {
            this.$store
              .dispatch("user/updateUser", {
                id: this.form.id,
                data: this.form,
              })
              .then(() => {
                this.$toast.success("Berhasil merubah data user");
                loading.hide();
                this.updateModal = false;
                this.form = {};
                this.getMe();
              })
              .catch((e) => {
                this.$toast.error(e);
                this.updateModal = false;
                loading.hide();
              });
          })
          .catch((e) => {
            this.$toast.error(e);
            this.updateModal = false;
            loading.hide();
          });
      }
    },
    getMe() {
      this.$store
        .dispatch("auth/me")
        .then((resp) => {
          this.user = resp.data;
          this.getInbox();
          this.getRooms();
        })
        .catch((e) => {
          this.$toast.error(e);
        });
    },
    getRooms() {
      var params = {
        sortby: "id",
        sorttype: "desc",
        row: 3,
        role_id: this.user.role.id,
      };
      this.$store
        .dispatch("room/getRoom", params)
        .then((resp) => {

          this.rooms = resp.data;
          console.log("room", this.rooms);
        })
        .catch((e) => {
          this.$toast.error(e);
        });
    },
    getInbox() {
      var _params = {
        sortby: "id",
        sorttype: "desc",
        row: 3,
      };
      this.$store
        .dispatch("message/getInbox", {
          id: this.user.role.id,
          params: _params,
        })
        .then((resp) => {
          this.inbox = resp.data.data;
        })
        .catch((e) => {
          this.$toast.error(e);
        });
    },
    getRole() {
      let _params = {
        sorttype: "asc",
        sortby: "id",
        row: 100,
      };
      this.$store
        .dispatch("role/getRole", _params)
        .then((resp) => {
          this.roles = resp.data.data;
          console.log(this.roles);
        })
        .catch((e) => {
          this.$toast.error("gagal mengambil data role \n", e);
        });
    },
    getPrasarana() {
      var loading = this.$loading.show()
      console.log(this.paramPrasarana);
      this.$store
        // .dispatch("paket_pekerjaan/getPaketPekerjaan", this.paramPrasarana)
        .dispatch("paket_pekerjaan/getAllPaketPekerjaan", this.paramPrasarana)
        .then((resp) => {
          this.items = resp.data.data
          this.total = resp.data.total

          // khusus untuk checkbox
          this.selectedItems = []
          this.items.forEach((element) => {
            if (this.isSelectedAll) {
              element.select = true
              this.selectedItems.push(element.id)
            } else {
              element.select = false
            }
          })
          loading.hide()
        })
        .catch((e) => {
          this.$toast.error("gagal mengambil data  \n", e)
          loading.hide()
        })
    },
    getKecamatan() {
      this.$store
        .dispatch("sbu/getKecamatan", this.params)
        .then((resp) => {
          this.kecamatans = resp.data;
        })
        .catch((e) => {
          this.$toast.error("gagal mengambil data  \n", e)
        })
    },
    getCategory() {
      this.$store
        .dispatch("category/getCategory", this.params)
        .then((resp) => {
          this.category = resp.data.data;
        })
        .catch((e) => {
          this.$toast.error("gagal mengambil data  \n", e)
        })
    },
    getPrasaranaAll() {
      var loading = this.$loading.show()
      console.log(this.paramPrasarana2);
      this.$store
        .dispatch("paket_pekerjaan/getAllPaketPekerjaan", this.paramPrasarana2)
        .then((resp) => {
          this.items2 = resp.data.data
          this.total = resp.data.total

          // khusus untuk checkbox
          this.selectedItems2 = []
          this.items2.forEach((element) => {
            if (this.isSelectedAll) {
              element.select = true
              this.selectedItems2.push(element.id)
            } else {
              element.select = false
            }
          })
          loading.hide()
        })
        .catch((e) => {
          this.$toast.error("gagal mengambil data  \n", e)
          loading.hide()
        })
    },
    handleRowClick(item) {
      this.$router.push({ path: 'paket-pekerjaan', query: { 
        prasarana: item.jenis_pekerjaan,  
        tahun: this.paramPrasarana.year, 
        kecamatan: this.paramPrasarana.kecamatan,
        jenis_pemilihan: this.paramPrasarana.jenis_pemilihan,
        jenis_lahan: this.paramPrasarana.jenis_lahan,
      } });
    }
  },
  mounted() {
    this.getKecamatan();
    this.getCategory();
    this.getMe();
    this.getData();
    this.getRole();
    this.getPrasarana();
    this.getPrasaranaAll();
  },

  computed: {
    computedRole() {
      return this.roles.map((item) => {
        return {
          value: item.id,
          label: item.name,
        };
      });
    },
    filteredData() {
      return this.items.filter(item => {
        return (
          (this.paramPrasarana.kecamatan === '' || item.kecamatan === this.paramPrasarana.kecamatan) &&
          (this.paramPrasarana.year === '' || item.tahun_anggaran === this.paramPrasarana.year) &&
          (this.paramPrasarana.jenis_pemilihan === '' || item.jenis_pemilihan === this.paramPrasarana.jenis_pemilihan) &&
          (this.paramPrasarana.jenis_lahan === '' || item.jenis_lahan === this.paramPrasarana.jenis_lahan) &&
          (this.paramPrasarana.jenis_pekerjaan === '' || item.jenis_pekerjaan === this.paramPrasarana.jenis_pekerjaan)
        );
      });
    },
    groupedData() {
      const grouped = {};
      let totalNilaiKontrak = 0;

      this.filteredData.forEach(item => {
        if (!grouped[item.jenis_pekerjaan]) {
          grouped[item.jenis_pekerjaan] = {
            jenis_pekerjaan: item.jenis_pekerjaan,
            jumlah_prasarana: 0,
            nilai_kontrak: 0,
            volume: 0,
          };
        }

        const nilai_anggaran = parseFloat(item.nilai_kontrak) || 0;
        const nilai_volume = parseFloat(item.volume) || 0;

        grouped[item.jenis_pekerjaan].jumlah_prasarana += 1; // Count occurrences as jumlah prasarana
        grouped[item.jenis_pekerjaan].nilai_kontrak += nilai_anggaran;
        grouped[item.jenis_pekerjaan].volume += nilai_volume;

        totalNilaiKontrak += nilai_anggaran;
      });

      console.log('Grouped Data:', grouped); // Log the grouped data
      return {
        data: Object.values(grouped),
        totalNilaiKontrak,
      };
    },
    filteredData2() {
      return this.items2.filter(item => {
        return (
          (this.paramPrasarana2.kecamatan === '' || item.kecamatan === this.paramPrasarana2.kecamatan) &&
          (this.paramPrasarana2.jenis_pemilihan === '' || item.jenis_pemilihan === this.paramPrasarana2.jenis_pemilihan) &&
          (this.paramPrasarana2.jenis_lahan === '' || item.jenis_lahan === this.paramPrasarana2.jenis_lahan) &&
          (this.paramPrasarana2.jenis_pekerjaan === '' || item.jenis_pekerjaan === this.paramPrasarana2.jenis_pekerjaan)
        );
      });
    },
    groupedData2() {
      const grouped = {};
      let totalNilaiKontrak = 0;

      this.filteredData2.forEach(item => {
        if (!grouped[item.jenis_pekerjaan]) {
          grouped[item.jenis_pekerjaan] = {
            jenis_pekerjaan: item.jenis_pekerjaan,
            jumlah_prasarana: 0,
            nilai_kontrak: 0,
          };
        }

        const nilai_anggaran = parseFloat(item.nilai_kontrak) || 0;

        grouped[item.jenis_pekerjaan].jumlah_prasarana += 1; // Count occurrences as jumlah prasarana
        grouped[item.jenis_pekerjaan].nilai_kontrak += nilai_anggaran;

        totalNilaiKontrak += nilai_anggaran;
      });

      console.log('Grouped Data:', grouped); // Log the grouped data
      return {
        data: Object.values(grouped),
        totalNilaiKontrak,
      };
    },
  },
};
</script>

<style scoped>
.border-top {
  border-top: 3px solid #1d4374 !important;
}

/* .dytable {
  display: block;
  min-width: 100%;
  overflow-x: auto;
} */

.scroll {
  overflow-y: auto;
  height: 150px;
}
</style>

<style>
table {
  display: table;
  width: 100%;
  overflow-x: auto;
  white-space: nowrap;
}
</style>