<template>
  <canvas ref="myChart" style="height:300px"></canvas>
</template>

<script>
// import { CChartBar } from '@coreui/vue-chartjs'
// import { deepObjectsMerge } from '@coreui/utils/src'
import { Chart } from 'chart.js';

export default {
  name: 'CChartBarExample',
  // components: { CChartBar },
    data() {
    return {
      paket_pekerjaan: [],
      total: [],
      colors: []
    }
  },
  props: {
    tahun: {
      type: String,
      default: () => 2023
    },
  },
  // computed: {
  //   defaultDatasets () {
  //     return [
  //       {
  //         label: 'Jumlah Prasarana',
  //         backgroundColor: this.colors,
  //         data: this.total
  //       }
  //     ]
  //   },
  //   defaultOptions () {
  //     return {
  //       maintainAspectRatio: false,
  //       responsive: true,
  //     }
  //   },
  //   computedOptions () {
  //     return deepObjectsMerge(this.defaultOptions, this.options || {})
  //   }
  // },
    methods: {
    // generate random color
    getRandomColor() {
        var letters = '0123456789ABCDEF';
        var color = '#';
        for (var i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    },
    createChart() {
      const ctx = this.$refs.myChart.getContext('2d');
      this.chart = new Chart(ctx, {
        type: 'bar',
        data: {
          labels: this.paket_pekerjaan,
          datasets: [
            {
              label: 'Jumlah Laporan',
              backgroundColor: this.colors,
              data: this.total
            }
          ]
        },
        options: {
          responsive: false,
          maintainAspectRatio: false,
          onClick: (event, elements) => this.handleBarClick(event, elements)
        }
      });
    },
    handleBarClick(event, elements) {
      if (elements.length > 0) {
        const elementIndex = elements[0]._index;
        const paketPekerjaan = this.paket_pekerjaan[elementIndex];
        this.$router.push({ path: 'paket-pekerjaan', query: { prasarana: paketPekerjaan,  tahun: this.tahun } });
      }
    }
  },
  mounted() {
      this.$store.dispatch("paket_pekerjaan/persentase", {tahun: this.tahun}).then((resp) => {
        resp.data.forEach(element => {
          this.paket_pekerjaan.push(element.jenis_pekerjaan)
          this.total.push(element.total)
          this.colors.push(this.getRandomColor())
        });
        this.createChart();
      }).catch(e => {
        this.$toast.error(e);
      });
  },
}
</script>
